
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 5px;
}




.login-h2 {
    font-size: 65px;
    color: #222;
    padding-bottom: 5px; /* Adjust the padding as needed */
    transition-duration: 0.4s;
    user-select: none;
}

/*
.login-main-container {
    
}
*/

.login-logo-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    clip-path: inset(0 0 0 20%);
    margin-left: -4%; /* Adjust this value to match the clip-path inset */
}

.logo {
    width: 384px;
    height: 216px;
    object-fit: cover;
}

.login-main-container {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh; /* Ensures the container takes up the full viewport height */
}

.login-container {
    align-self: center;
    width: 640px;
    height: auto;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding-bottom: 20px;
}

.login-logo {
    max-width: 200px; /* Adjust the width of the logo as needed */
    margin-bottom: 20px; /* Add some space between the logo and the input field */
}

.login-token-input {
    color: #222;
    padding: 15px; /* Increase padding to give more space */
    font-size: 22px;
    border: 2px solid #222;
    border-radius: 8px;
    width: 100%;
    max-width: 80%;
    box-sizing: border-box;
    margin-top: 10px; /* Adjust margin top */
    text-align: center;
    transition: 0.2s;
}


.login-loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    transition: 0.4s;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }


/* Styling for the buttons */
.login-button {
    margin-top: 15px;
    /* margin-bottom: 10px; /* Adjust the spacing between buttons */
    width: 510px;
    padding: 10px;
    border: none;
    background-color: #222;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition-duration: 0.4s;
}

/* Remove bottom margin from the last button */
.login-button:last-child {
    margin-bottom: 0;
}

  button[class="login-button"]:hover {
    background-color: #444;
}



.login-mode-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

body.dark-mode {
    background-color: #222; /* Dark background color */
    color: #fff; /* Light text color */
}

body.dark-mode .container {
    background-color: #222; /* Dark background color */
    color: #fff; /* Light text color */
}

body.dark-mode .login-container {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}


body.dark-mode .login-token-input {
    background-color: #444; /* Dark input background color */
    color: #fff; /* Light text color */
    border-color: #888; /* Dark border color */
}

body.dark-mode .button {
    background-color: #444; /* Dark input background color */
    
    color: #fff; /* Light text color */
    border-color: #ccc; /* Dark border color */
}

body.dark-mode .button:hover {
    background-color: #888;
}
