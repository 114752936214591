
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 5px;
}

.popup.unselectable {
    user-select: none;
}
  

#popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it's above other content */
    display: none; /* Initially hidden */
    transition: opacity 0.4s ease;
}



.popup {
    width: 400px;
    background-color: #fff;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 30px 30px;
    color: #333;
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 1000; /* Ensure it's above the overlay */
}
.open-popup {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.popup h1 {
    /*font-size: 38px;*/
    margin: 30px 0 10px;
}

.popup button {
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}


.home-token-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.home-display-token {
    margin-right: 10px;
    margin-top: 10px;
    font-weight: bold;
    font-style: italic;
    color: #222;
}

  
/* Styling for the main container */
.home-main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1600px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
    padding-top: 10px;
}

/* Styling for the container */
.home-container {
    flex: 1;
    margin-right: 50px; /* Adjust spacing between containers */
    display: flex; /* Make container flex */
    flex-direction: column; /* Arrange child elements vertically */
    /*padding: 10px; /* Adjust padding */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}

/* Styling for the heading */
.home-h2 {
    color: #222;
    padding-bottom: 5px; /* Adjust the padding as needed */
    transition-duration: 0.4s;
}


/* Styling for the input and output boxes */
textarea {
    width: calc(100%); /* Adjust width to consider padding */
    height: 650px;
    background-color: #222;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    resize: vertical;
    white-space: pre-line;
    resize: none;
    transition-duration: 0.4s;
    box-sizing: border-box; /* Include padding and border in the width calculation */
}

/* Styling for the container content */
.home-container-content {
    width: calc(100%);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

/* Styling for the text box */
.home-info-text-box-left, .home-info-text-box-right {
    width: calc(80% - 10px); /* Adjust the width as needed */
    color: white; /* Set text color to white */
    background-color: #222;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 32px;
    font-family: Arial, sans-serif;
    resize: none;
    white-space: pre-line; /* Preserve line breaks */
    transition-duration: 0.4s;
  
    /* Center the text to the left-most center */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.home-info-text-box-value {
    font-weight: bold;
    white-space: nowrap; /* Prevent line breaks */
}

.home-info-text-box-left::before {
    content: "Number of Tokens |"; /* Add the content before the element */
    margin-right: 5px; /* Adjust the space between the content and the value */
}

.home-info-text-box-right::before {
    content: "Total Tokens Used |"; /* Add the content before the element */
    margin-right: 5px; /* Adjust the space between the content and the value */
}



.home-logout-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.home-logout-button-container .home-button {
    width: 7%;
    margin-right: 10px; /* Reset margin to prevent extra space */
}



/* Styling for the buttons container */
.home-buttons-container {
    width: calc(20% - 10px); /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Styling for the buttons */
.home-button {
    margin-bottom: 10px; /* Adjust the spacing between buttons */
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #222;
    color: white;
    border-color: #ccc; /* Light border color */
    border-width: 1px; /* Set border width */
    border-style: solid; /* Set border style */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition-duration: 0.4s;
}

/* Remove bottom margin from the last button */
.home-button:last-child {
    margin-bottom: 0;
}

  button[class="home-button"]:hover {
    background-color: #444;
}


.home-loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    /*transition: 0.4s;*/
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }




  .home-mode-toggle {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    font-size: 24px;
}

body.dark-mode .home-display-token {
    color: #fff;
}

body.dark-mode {
    background-color: #222; /* Dark background color */
    color: #fff; /* Light text color */
}

body.dark-mode .container {
    background-color: #222; /* Dark background color */
    color: #fff; /* Light text color */
}

body.dark-mode h2 {
    color: #fff;
}

body.dark-mode .token-input {
    background-color: #444; /* Dark input background color */
    color: #fff; /* Light text color */
    border-color: #ccc; /* Dark border color */
}


body.dark-mode .text-box {
    background-color: #444;
    color: white; /* Set text color to white */
}


body.dark-mode .info-text-box {
    color: white; /* Set text color to white */
    background-color: #444;
    border: 1px solid #ccc;
}

body.dark-mode .button {
    background-color: #444; /* Dark input background color */
    
    color: #fff; /* Light text color */
    border-color: #ccc; /* Dark border color */
}

body.dark-mode .button:hover {
    background-color: #888;
}
